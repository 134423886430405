import React from 'react';
import PropTypes from 'prop-types';

import './car-news-badge.scss';

export function CarNewsBadge({ icon, text }) {
  return (
    <span className="car-news-badge badge d-inline-flex align-items-center text-white size-12 text-shadow-contrast bg-orange-20 px-0_5 py-0_25">
      <span className={`icon-${icon} me-0_25`} aria-hidden />
      <span>{text}</span>
    </span>
  );
}

CarNewsBadge.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
};

CarNewsBadge.defaultProps = {
  icon: 'fire',
  text: 'Trending',
};
