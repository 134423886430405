import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { AnimatedSticky } from 'site-modules/shared/components/animated-sticky/animated-sticky';
import { AnchorNavContent } from './anchor-nav-content';

import './anchor-nav-sticky.scss';

/**
 * To work properly with offsets anchors should be <ScrollElement id={anchorId} />
 * Example: client/site-modules/editorial/components/top-rated/vehicle-section/vehicle-section.jsx
 */
export function AnchorNavSticky({
  isMobile,
  className,
  navList,
  title,
  stickyTitle,
  linkTrackingId,
  stickyLinkTrackingId,
  hasSeparator,
  stickyClassName,
  navWrapperClassName,
  titleClassName,
  subLinkClassName,
  arrowClassName,
  stickyArrowClassName,
  subArrowClassName,
  containerClassName,
  anchorNavContentClassName,
  stickyAnchorNavContentClassName,
  listClassName,
  stickyListClassName,
  itemClassName,
  stickyItemClassName,
  linkClassName,
  stickyLinkClassName,
  linkActiveClassName,
  stickyLinkActiveClassName,
  creativeId,
  ariaLabel,
  noScroll,
  hideSticky,
  useItemClassName,
  useNoFollow,
}) {
  if (!navList) {
    return null;
  }

  const stickyFragmentTitle = stickyTitle || title;

  return (
    <div className={classnames('anchor-nav-sticky', className)} data-tracking-parent={creativeId}>
      <div className={containerClassName}>
        <AnimatedSticky
          identifierClass="anchor-nav-sticky"
          stickyClassName={classnames('nav-wrapper d-flex align-items-center bg-white', stickyClassName)}
          className={classnames('nav-wrapper d-flex align-items-center', navWrapperClassName)}
          hideSticky={hideSticky}
          stickyFragment={
            <Fragment>
              {!!stickyFragmentTitle && (
                <div className={classnames('nav-title', titleClassName)}>{stickyFragmentTitle}</div>
              )}
              <AnchorNavContent
                navList={navList}
                linkTrackingId={stickyLinkTrackingId || linkTrackingId}
                isMobile={isMobile}
                ariaLabel="sticky skip to section"
                className={stickyAnchorNavContentClassName || anchorNavContentClassName}
                listClassName={stickyListClassName || listClassName}
                itemClassName={stickyItemClassName || itemClassName}
                linkClassName={stickyLinkClassName || linkClassName}
                activeClass={stickyLinkActiveClassName || linkActiveClassName}
                arrowClassName={stickyArrowClassName || arrowClassName}
                isStickyFragment
                useItemClassName={useItemClassName}
              />
            </Fragment>
          }
        >
          {!!title && <div className={classnames('nav-title', titleClassName)}>{title}</div>}
          <AnchorNavContent
            navList={navList}
            noScroll={noScroll}
            subLinkClassName={subLinkClassName}
            subArrowClassName={subArrowClassName}
            linkTrackingId={linkTrackingId}
            isMobile={isMobile}
            ariaLabel={ariaLabel}
            className={anchorNavContentClassName}
            arrowClassName={arrowClassName}
            listClassName={listClassName}
            itemClassName={itemClassName}
            linkClassName={linkClassName}
            activeClass={linkActiveClassName}
            useItemClassName={useItemClassName}
            useNoFollow={useNoFollow}
          />
        </AnimatedSticky>
        {hasSeparator && <hr className="w-100 mt-0 border-gray" />}
      </div>
    </div>
  );
}

AnchorNavSticky.propTypes = {
  navList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      hash: PropTypes.string,
    })
  ),
  title: PropTypes.string,
  stickyTitle: PropTypes.string,
  className: PropTypes.string,
  linkTrackingId: PropTypes.string,
  stickyLinkTrackingId: PropTypes.string,
  isMobile: PropTypes.bool,
  hasSeparator: PropTypes.bool,
  stickyClassName: PropTypes.string,
  navWrapperClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  subLinkClassName: PropTypes.string,
  arrowClassName: PropTypes.string,
  stickyArrowClassName: PropTypes.string,
  subArrowClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  anchorNavContentClassName: PropTypes.string,
  stickyAnchorNavContentClassName: PropTypes.string,
  listClassName: PropTypes.string,
  stickyListClassName: PropTypes.string,
  itemClassName: PropTypes.string,
  stickyItemClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  stickyLinkClassName: PropTypes.string,
  linkActiveClassName: PropTypes.string,
  stickyLinkActiveClassName: PropTypes.string,
  creativeId: PropTypes.string,
  ariaLabel: PropTypes.string,
  noScroll: PropTypes.bool,
  hideSticky: PropTypes.bool,
  useItemClassName: PropTypes.bool,
  useNoFollow: PropTypes.bool,
};

AnchorNavSticky.defaultProps = {
  navList: null,
  title: 'Jump to:',
  stickyTitle: null,
  className: null,
  linkTrackingId: 'view_content',
  stickyLinkTrackingId: undefined,
  isMobile: false,
  hasSeparator: true,
  stickyClassName: null,
  navWrapperClassName: 'bg-white',
  titleClassName: 'heading-5 pe-1',
  subLinkClassName: undefined,
  arrowClassName: undefined,
  stickyArrowClassName: undefined,
  subArrowClassName: null,
  containerClassName: 'container',
  anchorNavContentClassName: undefined,
  stickyAnchorNavContentClassName: undefined,
  listClassName: undefined,
  stickyListClassName: undefined,
  itemClassName: undefined,
  stickyItemClassName: undefined,
  linkClassName: undefined,
  stickyLinkClassName: undefined,
  linkActiveClassName: undefined,
  stickyLinkActiveClassName: undefined,
  creativeId: undefined,
  ariaLabel: 'skip to section',
  noScroll: false,
  hideSticky: false,
  useItemClassName: true,
  useNoFollow: true,
};
