import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { getValidImageUrl, SOURCES } from 'client/utils/image-helpers';
import { Image } from 'site-modules/shared/components/image/image';
import { Link } from 'site-modules/shared/components/link/link';
import { isEmpty } from 'lodash';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { CarNewsBadge } from 'site-modules/editorial/components/car-news/car-news-badge/car-news-badge';
import { TopRatedRibbon } from 'site-modules/shared/components/top-rated-ribbon/top-rated-ribbon';
import { AdLabel } from 'site-modules/shared/components/native-ad/native-ad-components/ad-label/ad-label';
import { CtaButtonText } from 'site-modules/shared/components/native-ad/native-ad-components/cta-button-text/cta-button-text';

import './news-card.scss';

const DEVICE = {
  true: 'mobile',
  false: 'wired',
};
const TARGET_URL = {
  true: '_blank',
};

export function NewsCard({ content, isVisible, isLazyLoadImages, isMobile }) {
  const isTrending = content.metadata('trendingStory').boolean();
  const authorImage = content.metadata('authorImage').value();
  const authorName = content.metadata('authorName').value(content.metadata('author').value());
  const articleDate = content.metadata('article-date').value();
  const title = content.metadata('title').value('');
  const promoText = content.metadata('promo-text').value('');
  const articleHref = content.metadata(`link-url-${DEVICE[isMobile]}`).value(content.metadata('link-url').value());
  const hasPromoText = promoText && promoText !== '&nbsp;';
  const headerTitle = hasPromoText ? promoText : title;
  const hasTopRatedBadge = content.metadata('topRatedBadge').boolean();
  const sponsoredTitle = content.metadata('sponsored-title').value();
  const urlTargetNew = content.metadata('urlTargetNew').value();
  const adContent = content.metadata('ad-content').value();
  const isAdContent = !!adContent;

  if (!articleHref) return null;

  return (
    <div className="news-card car-news-card h-100">
      <Link
        to={articleHref}
        disabled={!isVisible}
        data-tracking-id="view_content"
        data-tracking-value={headerTitle}
        target={TARGET_URL[urlTargetNew]}
      >
        <div className="card-img-wrapper mw-100 pos-r">
          <Image
            imageId={content
              .metadata('media-image')
              .value('')
              .replace('300.jpg', isMobile ? '300.jpg' : '717.jpg')}
            source=""
            alt=""
            lazy={isLazyLoadImages}
            className="img-fluid card-image w-100"
          />
          {hasTopRatedBadge && <TopRatedRibbon color="blue" style={{ position: 'absolute', top: '12px', left: '0' }} />}
          {isAdContent && <AdLabel />}
        </div>
        {isTrending && <CarNewsBadge />}
        <div className="article-info mt-1">
          {!!sponsoredTitle && (
            <div className="disclaimer text-cool-gray-30 text-uppercase fw-bold mb-0_25">{sponsoredTitle}</div>
          )}
          {!!title && hasPromoText && (
            <div className="size-12 text-uppercase fw-bold text-blue-40 mb-0_5">
              <ContentFragment>{title}</ContentFragment>
            </div>
          )}
          <h3 className="article-title heading-5 mb-0_5">
            <ContentFragment>{headerTitle}</ContentFragment>
          </h3>
          {isAdContent && (
            <div className="text-blue-50">
              <CtaButtonText text={adContent} />
            </div>
          )}
          {authorImage ? (
            <div className="d-flex justify-content-center align-items-center mt-0_5">
              <div className="author-portrait me-0_5">
                <Image
                  imageId={getValidImageUrl(authorImage, SOURCES.STATIC)}
                  source=""
                  alt=""
                  lazy={isLazyLoadImages}
                  className="h-100 w-100 mw-100 mh-100"
                />
              </div>
              <div className="d-flex flex-column text-start">
                <span className="fw-bold text-gray-darker size-12">{authorName}</span>
                <span className="size-12 text-cool-gray-40">{articleDate}</span>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-md-column flex-lg-row align-items-center align-items-md-start justify-content-md-center align-items-lg-center justify-content-lg-start">
              {authorName && <span className="text-gray-darker size-12">{authorName}</span>}
              <span
                className={classnames('d-block d-md-none d-lg-block text-gray-darker mx-0_5', {
                  invisible: !articleDate && isEmpty(articleDate),
                })}
              >
                &middot;
              </span>
              {articleDate && <span className="size-12 text-cool-gray-40">{articleDate}</span>}
            </div>
          )}
        </div>
      </Link>
    </div>
  );
}

NewsCard.propTypes = {
  content: CmsEntities.Content,
  isVisible: PropTypes.bool,
  isLazyLoadImages: PropTypes.bool,
  isMobile: PropTypes.bool,
};

NewsCard.defaultProps = {
  content: DEFAULT_CONTENT,
  isVisible: true,
  isLazyLoadImages: true,
  isMobile: false,
};
